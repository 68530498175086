<template>
  <ui-card v-if="sessions.length === 0">
    <p class="font-bold">
      {{ `${$t("placeholders.no_sessions_registered_on_course")}` }}
    </p>
  </ui-card>
  <responsive-ui-list
    v-else
    :heading="heading"
    :headings="[
      $t('fields.date'),
      $tc('models.group', 1),
      $t('fields.location'),
      $t('language.language'),
      $tc('misc.registered', 2),
      $t('actions.complete'),
    ]"
    :sort="[
      { prop: 'startDateTime', type: false },
      undefined,
      { prop: 'location', type: false },
      { prop: 'language', type: false },
      { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
    ]"
    :items="sessions"
  >
    <template
      v-if="$slots.actions"
      v-slot:actions
    >
      <slot name="actions"></slot>
    </template>

    <template v-slot:default="props">
      <template>
        <ui-list-data
          class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') === $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                    ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
                `"
        ></ui-list-data>
        <ui-list-data
          class="list_data"
          v-if="
            $d(new Date(props.item.startDateTime), 'fullShort') != $d(new Date(props.item.endDateTime), 'fullShort')
          "
          :text="`
                    ${$d(new Date(props.item.startDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')} 
                    - 
                    ${$d(new Date(props.item.endDateTime), 'fullShort', $i18n.locale === 'no' ? 'no' : 'en')}
                `"
        ></ui-list-data>
      </template>
      <ui-list-data
        class="list_data"
        :text="`
                ${$d(new Date(props.item.startDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}
                -
                ${$d(new Date(props.item.endDateTime), 'time', $i18n.locale === 'no' ? 'no' : 'en')}`"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        type="tag"
        :text="props.item.location"
      ></ui-list-data>
      <ui-list-data
        class="list_data"
        small
        type="tag"
        :text="
          props.item.language === 'en'
            ? $t('language.english', 'en').toString()
            : $t('language.norwegian', 'no').toString()
        "
      ></ui-list-data>
      <template v-if="sessions">
        <ui-list-data-progress
          class="list_data"
          v-if="typeof getPercentage(props.item) === 'number'"
          :percentage="getPercentage(props.item)"
          :progressDetails="props.item.progress"
        ></ui-list-data-progress>
        <ui-list-data
          class="list_data"
          v-if="getPercentage(props.item) === false"
          :text="$t('labels.course.no_users_assigned')"
        ></ui-list-data>
      </template>
      <ui-list-data
        class="list_data"
        small
      >
        <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
          <ui-button
            type="green"
            size="small"
            uppercase
            @click="
              $router.push({
                name: 'courseevent-session-view',
                params: { id: props.item.courseEvent, sessionId: props.item.id },
              })
            "
          >
            {{ $t("actions.show_content") }}
          </ui-button>
        </auth-checker>
        <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin', 'Instructor']">
          <ui-button
            v-if="props.item.active === true"
            class="ml-6"
            type="grey"
            size="small"
            uppercase
            @click="updateSession(props.item.id, false)"
          >
          <i class="el-icon-check"></i> {{ $t("actions.complete") }}
          </ui-button>
          <ui-button
            v-else
            class="ml-6"
            type="red"
            size="small"
            uppercase
            @click="updateSession(props.item.id, true)"
          >
          <i class="el-icon-close"></i> {{ $t("actions.undo") }}
          </ui-button>
        </auth-checker>
      </ui-list-data>
    </template>
  </responsive-ui-list>
</template>

<script>
import ResponsiveUiList from '../components/ResponsiveUiList'
import UiListData from "@/components/ui/UiListData";
import UiListDataProgress from "@/components/ui/UiListDataProgress";
import AuthChecker from "../../auth/components/AuthChecker";
import UiButton from "@/components/ui/UiButton";
import { updateSessionStatus } from "../api";
import UiCard from "@/components/ui/UiCard.vue";
import { capitalized } from "@/extras";

export default {
  props: {
    heading: {
      type: String,
    },
    sessions: {
      type: Array,
      required: true,
    },
  },

  watch: {
    courseEvents(_, __) {
      // Create uppercase locations to ensure correct sorting
      this.sessions.forEach((session) => {
        session.location = capitalized(session.location);
      });
    },
  },

  components: {
    UiCard,
    ResponsiveUiList,
    UiListData,
    UiListDataProgress,
    AuthChecker,
    UiButton,
  },

  methods: {
    /**
     * Updates a session with a new activity 
     * @param {string} sessionId 
     * @param {boolean} active true if session should be active, false otherwise
     */
    async updateSession(sessionId, active) {
      this.loading = true;

      // Show confirmation message - used needs to press OK to continue
      const userConfirmString = active ? 'confirm.undo_finished_group' : 'confirm.finish_group';
      const userConfirmation = confirm(this.$t(userConfirmString));
      if (!userConfirmation) {
        return;
      }

      // Updating active
      const session = this.sessions.find((a) => a.id === sessionId);
      session.active = active;

      const response = await updateSessionStatus(session);

      // Updating local session + showing success status if API returned status-code 200
      if (response.status === 200) {
        this.sessions[this.sessions.findIndex((a) => a.id === sessionId)].active = response.data.active;

        if (response.data.active) {
          this.$message({ message: this.$t("server_feedback.session_marked_active").toString(), type: "success" });
        } else {
          this.$message({ message: this.$t("server_feedback.session_marked_inactive").toString(), type: "success" });
        }
      }
      else {
        this.$message({ message: this.$t("server_feedback.generic_error").toString(), type: "error" });
      }

      this.loading = false;
    },

    getPercentage(item) {
      if (item.progress && item.progress.assignedCount) {
        return item.progress.progressPercentage;
      } else {
        if (item.assignedCount) {
          const per = Math.floor((item.completedCount / item.assignedCount) * 100);
          return per;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
.list_data {
  padding: 10px 24px;
}
</style>
